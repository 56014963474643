<script setup lang="ts">
import DisclaimerPopVue from "@/components/DisclaimerPop.vue";
import IcpComp from "@/components/IcpComp.vue";

import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
import { ref, watch } from "vue";
import { useMyStrategy } from "@/hooks/useMyStrategy";
import router from "@/router";

// sdk监听生命周期
Haa.util().useLifeCycle();

let enterTimer: number | null = null;

const clearTimer = () => {
  if (enterTimer != null) {
    clearTimeout(enterTimer);
    enterTimer = null;
  }
};

const { strategy } = useMyStrategy();

const { oeReport, openWindow } = Haa.util().useOeReport();

// 全屏点击
const onFullScreenClick = (e: TouchEvent) => {
  if (strategy.value?.guide_click_enable === 0) return;

  if (strategy.value?.guide_click_enable === 1) {
    onRouterEnter("FullScreen", e);
  }
};

// 是否已触发跳转 网络不好时多次点击会触发多次上报
// const isTriggerJump = ref(false);

// 跳转函数 当前页面所有跳转得经过这个函数
const onRouterEnter = (msgType: string, e?: any) => {
  let appX, appY;

  if (e) {
    appX = e.touches ? e.touches[0].clientX : e.clientX;
    appY = e.touches ? e.touches[0].clientY : e.clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  // 避免网络延迟多次点击重复上报
  // if (isTriggerJump.value) return;

  // isTriggerJump.value = true;

  oeReport(
    OeReportType.Action,
    OeReportSubType.BtnClick,
    "Guide",
    msgType,
    undefined,
    appX,
    appY
  );

  onRouterPath();

  clearTimer();
};

const onRouterPath = () => {
  const result = openWindow({ skip: "1" });

  if (result) return;

  router.replace({ path: "/home" });
};

// 31038上报处理函数
const onCriticalClick = (e: TouchEvent) => {
  let appX, appY;
  if (e) {
    appX = e.touches[0].clientX;
    appY = e.touches[0].clientY;
    appX = Math.round(appX);
    appY = Math.round(appY);
  }

  oeReport(
    OeReportType.Action,
    OeReportSubType.NonCriticalAreaClick,
    "Guide",
    "NonCriticalAreaClick",
    undefined,
    appX,
    appY
  );
};

/** 处理策略控制事件处理方式 -----------------begin */
enum EventNames {
  Touchstart = "touchstart",
  Click = "click",
}

/** 默认使用touchstart触发点击 */
let hotEventName = ref(EventNames.Touchstart);
let notHotEventName = ref(EventNames.Touchstart);

const handleEventName = (type: number) => {
  // 0：全部touch_down触发跳转(默认);
  // 1：热点区域click触发，非热点区域touch_down触发跳转；
  // 2：热点区域touch_down触发，非热点区域click触发跳转;
  // 3：全部click触发跳转；1：热点区域click触发;
  const handleMap = [
    [EventNames.Touchstart, EventNames.Touchstart],
    [EventNames.Click, EventNames.Touchstart],
    [EventNames.Touchstart, EventNames.Click],
    [EventNames.Click, EventNames.Click],
  ];

  const [_hotEventName, _notHotEventName] = handleMap[type] ?? [];

  _hotEventName && (hotEventName.value = _hotEventName);
  _notHotEventName && (notHotEventName.value = _notHotEventName);
};
/** 处理策略控制事件处理方式 -----------------end */

/** 监听策略返回情况 */
watch(
  () => strategy.value,
  (res) => {
    if (!res) return;

    // 根据策略 是否倒计时离开引导页
    if (res.guide_timeout !== -1) {
      enterTimer = setTimeout(() => {
        onRouterEnter("Timerout_" + res.guide_timeout + "s");
      }, res.guide_timeout * 1000);
    }

    // 根据策略 判断是否指定触发事件类型
    handleEventName(res.guide_used_click);
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div
    class="guide-view-wrapper"
    v-slide
    v-on:[notHotEventName]="onFullScreenClick"
  >
    <div
      class="guide-content"
      v-on:[hotEventName]="onRouterEnter('CardItem', $event)"
      @mousedown="onRouterEnter('CardItem', $event)"
    >
      <img
        class="guide-card animate__animated animate__bounce"
        src="@/assets/images/guide_new/card_1.png"
      />
      <img
        class="guide-card animate__animated animate__bounce animate__delay-1s"
        src="@/assets/images/guide_new/card_2.png"
      />
      <img
        class="guide-card animate__animated animate__bounce animate__delay-2s"
        src="@/assets/images/guide_new/card_3.png"
      />
      <img
        class="guide-card animate__animated animate__bounce animate__delay-3s"
        src="@/assets/images/guide_new/card_4.png"
      />
      <img
        class="finger animate__animated animate__heartBeat animate__infinite"
        src="@/assets/images/guide_new/finger.png"
      />
    </div>

    <IcpComp class="c-icp"></IcpComp>
  </div>
</template>

<style scoped lang="less">
.guide-view-wrapper {
  height: 100vh;
  overflow: hidden;
  background-image: url("@/assets/images/guide_new/bg.png");
  background-size: 100% 100%;
  .guide-content {
    width: 100%;
    height: 50%;
    margin-top: 14%;
    position: relative;
    padding: 30px 30px 0;
    box-sizing: border-box;
    .guide-card {
      width: 340px;
      height: 380px;
      &:nth-child(2n) {
        transform: translateY(30px);
      }
    }
    .finger {
      width: 380px;
      height: 380px;
      position: absolute;
      top: 180px;
      right: 30px;
    }
  }
  .c-icp {
    width: 100%;
    max-width: 750px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    margin: 0;
  }
}
@media screen and (max-width: 786px) {
  .guide-view-wrapper {
    .guide-content {
      margin-top: 70%;
    }
  }
}
</style>
