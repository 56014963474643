const SPACE_DATA = {
  "u6980692":  [{space_js: "//fyxpskb.yxsktech.com/common/sn/resource/m_vpt/static/mv/source/c.js", space_src: "baidujs", space_type: "inters", space_id: "u6980692"}],
  "u6980693":  [{space_js: "//fyxpskb.yxsktech.com/common/t/common/o/n_wqunw_g.js", space_src: "baidujs", space_type: "native", space_id: "u6980693"}],
  "u6980694":  [{space_js: "//fyxpskb.yxsktech.com/site/up/static/oxr/production/v_o/source/xl.js", space_src: "baidujs", space_type: "native", space_id: "u6980694"}],
  "u6980695":  [{space_js: "//fyxpskb.yxsktech.com/site/vq/p/ys/openjs/wpy/source/n.js", space_src: "baidujs", space_type: "native", space_id: "u6980695"}],
  "u6980696":  [{space_js: "//fyxpskb.yxsktech.com/source/wrq/source/z/production/txqz_q.js", space_src: "baidujs", space_type: "inters", space_id: "u6980696"}],
  "u6980697":  [{space_js: "//fyxpskb.yxsktech.com/site/xsrau/source/y/static/r_at.js", space_src: "baidujs", space_type: "native", space_id: "u6980697"}],
  "u6980698":  [{space_js: "//fyxpskb.yxsktech.com/source/y/t/openjs/sbv/static/zsb/resource/v.js", space_src: "baidujs", space_type: "native", space_id: "u6980698"}],
  "u6980699":  [{space_js: "//fyxpskb.yxsktech.com/common/a_vu/dxbu/openjs/d/d.js", space_src: "baidujs", space_type: "native", space_id: "u6980699"}],
};
export const SPACE_MAP = {
Home_inter:  SPACE_DATA["u6980692"],
Home_banner:  SPACE_DATA["u6980693"],
Home_native:  SPACE_DATA["u6980694"],
Home_native_center1:  SPACE_DATA["u6980695"],
Detail_inter:  SPACE_DATA["u6980692"],
Detail_banner:  SPACE_DATA["u6980693"],
Detail_native:  SPACE_DATA["u6980694"],
Detail_native_center1:  SPACE_DATA["u6980695"],
Result_inter:  SPACE_DATA["u6980692"],
Result_banner:  SPACE_DATA["u6980693"],
Result_native:  SPACE_DATA["u6980694"],
Result_native_center1:  SPACE_DATA["u6980695"],
}
export const ALL_SPACE_MAP = {
  H5_YXAI_VO_3 : {
  Home_inter:  SPACE_DATA["u6980692"],
  Home_banner:  SPACE_DATA["u6980693"],
  Home_native:  SPACE_DATA["u6980694"],
  Home_native_center1:  SPACE_DATA["u6980695"],
  Detail_inter:  SPACE_DATA["u6980692"],
  Detail_banner:  SPACE_DATA["u6980693"],
  Detail_native:  SPACE_DATA["u6980694"],
  Detail_native_center1:  SPACE_DATA["u6980695"],
  Result_inter:  SPACE_DATA["u6980692"],
  Result_banner:  SPACE_DATA["u6980693"],
  Result_native:  SPACE_DATA["u6980694"],
  Result_native_center1:  SPACE_DATA["u6980695"],
  },
  H5_YXAI_VO_4 : {
  Home_inter:  SPACE_DATA["u6980696"],
  Home_banner:  SPACE_DATA["u6980697"],
  Home_native:  SPACE_DATA["u6980698"],
  Home_native_center1:  SPACE_DATA["u6980699"],
  Detail_inter:  SPACE_DATA["u6980696"],
  Detail_banner:  SPACE_DATA["u6980697"],
  Detail_native:  SPACE_DATA["u6980698"],
  Detail_native_center1:  SPACE_DATA["u6980699"],
  Result_inter:  SPACE_DATA["u6980696"],
  Result_banner:  SPACE_DATA["u6980697"],
  Result_native:  SPACE_DATA["u6980698"],
  Result_native_center1:  SPACE_DATA["u6980699"],
  },
  DEFAULT : {
  Home_inter:  SPACE_DATA["u6980692"],
  Home_banner:  SPACE_DATA["u6980693"],
  Home_native:  SPACE_DATA["u6980694"],
  Home_native_center1:  SPACE_DATA["u6980695"],
  Detail_inter:  SPACE_DATA["u6980692"],
  Detail_banner:  SPACE_DATA["u6980693"],
  Detail_native:  SPACE_DATA["u6980694"],
  Detail_native_center1:  SPACE_DATA["u6980695"],
  Result_inter:  SPACE_DATA["u6980692"],
  Result_banner:  SPACE_DATA["u6980693"],
  Result_native:  SPACE_DATA["u6980694"],
  Result_native_center1:  SPACE_DATA["u6980695"],
  },
};
